<script>
import Layout from "../../../../layouts/main";

import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      limit: 20,
      page: 1,
      selectedCurrency:{},
      suppliers: [],
      matchedCurrencies: [],
      transactions: [],
      currencies: [],
      accountingPeriods: [],
      accountFilter: {
        accounting_period_id: null,
        from: "",
        to: "",
      },
      search: "",
      totalCredit: 0,
      totalDebit: 0,
      index: 1,
      selectedSupplier: {},
    };
  },
  methods: {
    getAgentReport() {
      console.log({ accountFilter: this.accountFilter });
      this.http
        .post("purchases-agents/report", {
          id: this.selectedSupplier.id,
          currency_id:this.selectedCurrency.id,
          accountFilter: this.accountFilter,
        })
        .then((res) => {
          this.transactions = res.data;
          if(res.status && res.data.length==0){
            this.popup.alert({
              title: "popups.error",
              msg: this.$t("reports.no_data")
            });
          }
          else if (res.status) {
            console.log(res.data);

            this.totalCredit = 0;
            this.totalDebit = 0;
            this.transactions.forEach((transaction) => {
              if (transaction.type == "credit") {
                this.totalCredit += parseFloat(transaction?.value);
              } else {
                this.totalDebit += parseFloat(transaction?.value);
              }
            });
          }
        });
    },
    selectSupplier() {
      console.log("this.selectedSupplier",this.selectedSupplier);
      this.http
        .post("purchases-agents/select-supplier", {
          id: this.selectedSupplier.id,
        })
        .then((res) => {
          if (res.status) {
            this.currencies = res.currencies;
            console.log(res);
          }
        });
    },
    showLabel(agent){
      return this.$i18n.locale == "ar"
          ? `${agent?.code} ${agent?.name}`
          : `${agent?.code} ${agent?.name}`;
    },
    searchSuppliers(search) {
      this.http
        .post("purchases-agents/search", { search })
        .then((res) => {
          if (res.status) {
            this.suppliers = res.data
          }
        });
    },
    printReport() {
      window.print();
    },
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
        }
      });
    },
    searchCurrency(search) {
      this.matchedCurrencies = this.supplier_currencies.filter(currency => currency.name.toLowerCase().includes(search.toLowerCase()) || currency.name_en.toLowerCase().includes(search.toLowerCase() ))
    },
    showCurrencyLabel(currency){
      return this.$i18n.locale=='ar' ? currency?.name : currency?.name_en
    },
    getCurrencies() {
      this.http.get("currencies/active").then((res) => {
        if (res.status) {
          this.currencies = res.data;
        }
      });
    },
  },
  computed: {
    supplier_currencies(){
      if(this.selectedSupplier?.currency_id == this.currencies?.find(c=>c.is_functional)?.id){
        return [this.currencies?.find(c=>c.is_functional)]
      }
      else if(this.selectedSupplier){
        return [this.currencies?.find(c=>c.is_functional),this.currencies?.find(c=>c.id==this.selectedSupplier.currency_id)]
      }
      else{
        return []
      }
    },

  },
  created() {
    this.getAccountingPeriods();
    this.getCurrencies();
  },
};
</script>

<template>
  <Layout class="no-print">
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('reports.suppliers')"
    />

    <div class="row">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="getAgentReport()">
            <div class="p-3" style="background-color: rgb(246, 247, 248)">
              <div class="row">
                <div class="col-12 col-md-4">
                    <label for="" class="col-md-2.5 col-form-label">{{
                      $t("reports.select_sup")
                    }}</label>
                    <AutoComplete
                      v-model="selectedSupplier"
                      :suggestions="suppliers"
                      :complete-on-focus="true"
                      @item-select="selectSupplier(); supplier_currencies.length==1 ? selectedCurrency=supplier_currencies[0] : selectedCurrency='' ; transactions=''"
                      @complete="searchSuppliers($event.query)"
                      :optionLabel="showLabel"
                      style="color: #333 !important;width:100%"
                    />
                </div>
                <!-- <div class="col-3">

              </div> -->
                <div class="col-12 col-md-4">
                  <label
                      class="col-md-2.5 col-form-label"
                      for="userName"
                  >{{$t("purchases.currency")}}</label
                  >
                  <AutoComplete
                      v-model="selectedCurrency"
                      :complete-on-focus="true"
                      :suggestions="matchedCurrencies"
                      @complete="searchCurrency($event.query)"

                      :optionLabel="showCurrencyLabel"
                      style="color: #333 !important; border-radius: 10px !important;width:100%"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col d-inline">
                  <label for="" class="d-block">{{
                      $t("reports.period")
                    }}</label>

                  <select
                      v-model="accountFilter.accounting_period_id"
                      class="form-select"
                      :disabled="!selectedSupplier.id || !selectedCurrency.id"
                  >
                    <option
                        :value="period.id"
                        v-for="period in accountingPeriods"
                        :key="period"
                    >
                      {{
                        period?.start_date +
                        ` ${this.$i18n.locale == "ar" ? "الى" : "To"} ` +
                        period?.end_date
                      }}
                    </option>
                  </select>
                </div>
                <div class="col d-inline">
                  <label for="" class="d-block">{{ $t("reports.from") }}</label>
                  <input
                      v-model="accountFilter.from"
                      :disabled="!selectedSupplier.id || !selectedCurrency.id"
                      type="date"
                      class="form-control"
                  />
                </div>
                <div class="col d-inline">
                  <label for="" class="d-block">{{ $t("reports.to") }}</label>

                  <input
                      v-model="accountFilter.to"
                      :disabled="!selectedSupplier.id || !selectedCurrency.id"
                      type="date"
                      class="form-control"
                  />
                </div>
                <div class="col d-flex align-items-end">
                  <button
                      :disabled="!selectedSupplier.id || !selectedCurrency.id"
                      style="height: 40px"
                      class="btn btn-success"
                  >
                    {{ $t("popups.search") }}
                  </button>
                </div>

              </div>
            </div>
          </form>

          <div v-if="transactions.length > 0">
            <br />
            <button class="btn btn-success float-end" @click="printReport()">
              <i class="bx bx-printer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-if="transactions.length > 0">
      <div class="card card-body">
        <ReportsHeader
          :reportName="$t('reports.suppliers_statement')"
          :accountNo="selectedSupplier?.id"
          :accountName="transactions[0]?.name"
        />

        <div class="table-responsive">
          <table
            class="table table-centered table-nowrap table-striped table-hover align-middle"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <!-- <th scope="col">{{ $t("general_ledger.account_id") }}</th> -->
                <th scope="col">{{ $t("customers_report.date") }}</th>
                <!-- <th scope="col">{{ $t("customers_report.particular") }}</th> -->
                <th scope="col">{{ $t("customers_report.descr") }}</th>
                <th scope="col">{{ $t("customers_report.debit") }}</th>
                <th scope="col">{{ $t("customers_report.credit") }}</th>
                <th scope="col">{{ $t("customers_report.balance") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class=""
                v-for="transaction in transactions"
                :key="transaction"
              >
                <!-- <td>{{ transaction.code }}</td> -->
                <!-- <td>{{ transaction.name }}</td> -->
                <td>{{ transaction?.created.split("T")[0] }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? transaction?.descr
                      : transaction?.descr_en
                  }}
                </td>
                <td>
                  {{
                    transaction?.type == "debit"
                      ? parseFloat(transaction?.value).toLocaleString()
                      : ""
                  }}
                </td>
                <td>
                  {{
                    transaction?.type == "credit"
                      ? parseFloat(transaction?.value).toLocaleString()
                      : ""
                  }}
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td class="total-style">
                  {{ parseFloat(totalDebit).toLocaleString() }}
                </td>
                <td class="total-style">
                  {{ parseFloat(totalCredit).toLocaleString() }}
                </td>
                <td class="total-style">
                  {{0 > (parseFloat(totalCredit) - parseFloat(totalDebit)) ? "(":""}}
                  {{
                    (
                        parseFloat(totalCredit) - parseFloat(totalDebit)
                    ).toLocaleString()
                  }}
                  {{0 > (parseFloat(totalCredit) - parseFloat(totalDebit)) ? ")":""}}

                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <th>{{ $t("reports.report_date") }} :</th>
              <th>
                {{
                  `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                 ${new Date().getHours()}:${new Date().getMinutes()}`
                }}
              </th>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </Layout>

  <div class="row mt-4 print" v-if="transactions.length > 0">
    <div class="card card-body">
      <ReportsHeader
        :reportName="$t('reports.suppliers_statement')"
        :accountNo="selectedSupplier?.id"
        :accountName="transactions[0]?.name"
      />

      <div class="table-responsive">
        <table
          class="table table-centered table-nowrap table-striped table-hover align-middle"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <!-- <th scope="col">{{ $t("general_ledger.account_id") }}</th> -->
              <th scope="col">{{ $t("customers_report.date") }}</th>
              <!-- <th scope="col">{{ $t("customers_report.particular") }}</th> -->
              <th scope="col">{{ $t("customers_report.descr") }}</th>
              <th scope="col">{{ $t("customers_report.debit") }}</th>
              <th scope="col">{{ $t("customers_report.credit") }}</th>
              <th scope="col">{{ $t("customers_report.balance") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="transaction in transactions" :key="transaction">
              <!-- <td>{{ transaction.code }}</td> -->
              <!-- <td>{{ transaction.name }}</td> -->
              <td>{{ transaction?.created.split("T")[0] }}</td>
              <td>
                {{
                  $i18n.locale == "ar"
                    ? transaction?.descr
                    : transaction?.descr_en
                }}
              </td>
              <td>
                {{
                  transaction?.type == "debit"
                    ? parseFloat(transaction?.value).toLocaleString()
                    : ""
                }}
              </td>
              <td>
                {{
                  transaction?.type == "credit"
                    ? parseFloat(transaction?.value).toLocaleString()
                    : ""
                }}
              </td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td class="total-style">
                {{ parseFloat(totalDebit).toLocaleString() }}
              </td>
              <td class="total-style">
                {{ parseFloat(totalCredit).toLocaleString() }}
              </td>
              <td class="total-style">
                {{0 > (parseFloat(totalCredit) - parseFloat(totalDebit)) ? "(":""}}
                {{
                  (
                      parseFloat(totalCredit) - parseFloat(totalDebit)
                  ).toLocaleString()
                }}
                {{0 > (parseFloat(totalCredit) - parseFloat(totalDebit)) ? ")":""}}

              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <th>{{ $t("reports.report_date") }} :</th>
            <th>
              {{
                `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                 ${new Date().getHours()}:${new Date().getMinutes()}`
              }}
            </th>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
.print {
  display: none;
}
@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
}
</style>
